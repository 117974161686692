import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(-38.750000, 5.000000)">
        <path
          d="m 75.648023,58.201128 c 0.33296,-0.925587 2.874903,-7.917384 5.648754,-15.537328 2.77385,-7.619946 5.112737,-14.048174 5.197518,-14.284952 l 0.154155,-0.430506 h 2.845474 2.845482 l 5.80779,15.971908 5.807794,15.97191 -2.27203,-0.04319 -2.272029,-0.04319 -0.991168,-2.817854 C 97.874617,55.4381 97.17234,53.447994 96.859142,52.565457 l -0.56944,-1.604612 h -6.865548 c -6.380113,0 -6.875599,0.01935 -7.007667,0.273958 -0.07817,0.150676 -0.80272,2.158354 -1.61012,4.461504 l -1.468,4.18754 -2.14787,1.01e-4 -2.147864,1e-4 z M 94.914838,47.230911 c 0,-0.147634 -5.43215,-15.374051 -5.514032,-15.455933 -0.03816,-0.03818 -4.470108,12.2141 -5.56676,15.38956 -0.05325,0.154149 1.11558,0.195684 5.506605,0.195684 3.065804,0 5.574187,-0.05819 5.574187,-0.129311 z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
